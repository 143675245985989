import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IncomingMessage } from 'http';

/**
 * Generic api service to be used with `react-query` to fetch server data
 * Can specify the type of interface to be returned by calling function
 * api<Interface>
 */

export enum Methods {
  delete = 'delete',
  get = 'get',
  patch = 'patch',
  post = 'post',
  put = 'put',
}

const API_ROOT = process.env.NEXT_PUBLIC_API_ROOT;

export const baseUrl = API_ROOT;

axios.defaults.headers.common['Content-Type'] = 'application/json';

export interface ApiOptions {
  xPage?: string;
  headers?: Record<string, string | undefined>;
  onUploadProgress?: (event: ProgressEvent) => void;
}

export const api = async <Response>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Promise<Response> => {
  if (options?.xPage) {
    axios.defaults.headers.common['x-page'] = options.xPage;
  }
  try {
    const apiClient = axios.create({});
    let parsedUrl = url;

    parsedUrl = `${baseUrl}${parsedUrl}`;

    if (options?.headers) {
      apiClient.defaults.headers = options.headers;
    }

    const response = await apiClient({
      method,
      url: parsedUrl,
      data,
      timeout: 15000,
      withCredentials: true,
      baseURL: baseUrl,
    });
    return response.data;
  } catch (err: unknown) {
    const error = err as AxiosError;

    if (error.isAxiosError) {
      console.error('Error', error.response?.data);
      throw new Error(
        error.response?.data.msg ||
          'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    } else {
      console.error('Error', error);
      throw new Error(
        'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    }
  }
};

export const apiWithFormData = async <T>(
  url: string,
  data: FormData,
  options: ApiOptions = {},
): Promise<T> => {
  try {
    const parsedUrl = `${baseUrl}${url}`;

    const headers: Record<string, string | undefined> = {
      'x-page': options?.xPage,
      'Content-Type': 'multipart/form-data',
    };

    const config: AxiosRequestConfig = {
      headers,
      withCredentials: true,
      baseURL: baseUrl,
      onUploadProgress: options.onUploadProgress || undefined,
    };

    const response = await axios.post(parsedUrl, data, config);

    return response.data;
  } catch (err: unknown) {
    const error = err as AxiosError;

    if (error.isAxiosError) {
      throw new Error(
        error.response?.data.msg ||
          'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    } else {
      throw new Error(
        'Uh oh 😅, did not expect that! Please notify Solin team',
      );
    }
  }
};

export const serverApi = async <Data>(
  req: IncomingMessage,
  method: Methods,
  url: string,
  data?: any,
  headers?: Record<string, string | undefined>,
): Promise<Data | null> => {
  const parsedUrl = `${baseUrl}${url}`;
  try {
    const response = await axios({
      method,
      url: parsedUrl,
      data,
      headers: {
        ...headers,
        cookie: req.headers.cookie,
      },
    });

    return response.data;
  } catch (err: unknown) {
    console.error('Error', err);
    return null;
  }
};
